import { useEffect, useMemo, useCallback, useRef } from 'react'

const cacheKey = 'memo-cache-v1'

export const useManualCache = (key: string, method = 'cache') => {
  const isCached = useRef({ cached: false })

  const value = useMemo(() => {
    const cachedValue = localStorage.getItem(`${cacheKey}${key}`)
    if (cachedValue) {
      return JSON.parse(cachedValue)
    }
  }, [])

  const setValue = useCallback((toCache) => {
    if (isCached.current.cached) return
    if (toCache) {
      if (method === 'cache') {
        localStorage.setItem(`${cacheKey}${key}`, JSON.stringify(toCache))
      } else if (method?.startsWith('first-')) {
        const toCacheItem = toCache.slice(
          0,
          parseInt(method.replace('first-', ''))
        )
        toCacheItem.originalLength = toCache.length
        localStorage.setItem(`${cacheKey}${key}`, JSON.stringify(toCacheItem))
      }
      isCached.current.cached = true
    }
  }, [])

  return [value, setValue]
}

export const useCache = (key: string, toCache: any, method = 'cache') => {
  const value = useMemo(() => {
    const cachedValue = localStorage.getItem(`${cacheKey}${key}`)
    if (cachedValue) {
      return JSON.parse(cachedValue)
    }
  }, [])

  useEffect(() => {
    if (toCache) {
      if (method === 'cache') {
        localStorage.setItem(`${cacheKey}${key}`, JSON.stringify(toCache))
      } else if (method.startsWith('first-')) {
        localStorage.setItem(
          `${cacheKey}${key}`,
          JSON.stringify(
            toCache.slice(0, parseInt(method.replace('first-', '')))
          )
        )
      }
    }
  }, [toCache])

  return value
}

export const resetCache = () => {
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i)?.startsWith(cacheKey)) {
      localStorage.removeItem(localStorage.key(i)!)
    }
  }
  sessionStorage.removeItem('merchants.state')
}
