import moment from 'moment'
import { isBlank, toArray } from '.'
import { getRequest, postRequest } from '../../../services/api'
// import { scriptmanagerPost } from '../../services/scriptmanager'

const activityKey = 'impl_a'
const lastActivityCheckKey = 'impl_a_t'
const conversionKey = 'impl_c'
const lastConversionCheckKey = 'impl_c_t'
const checkExpireTime = 60000 // 1 minute

export const checkAccountActivity = async (): Promise<boolean> => {
  if (localStorage.getItem(activityKey)) return true

  const lastCheckTime = localStorage.getItem(lastActivityCheckKey)
  if (lastCheckTime && Date.now() - parseInt(lastCheckTime) < checkExpireTime) {
    return false
  }

  localStorage.setItem(lastActivityCheckKey, Date.now().toString())

  const channels = await getRequest('/channels/v1/list')
  const channelStats = await getRequest('/channels/v1/stats')
  for (const channel of toArray(channels.data)) {
    // const scriptVerify = await scriptmanagerPost('/script/check', {
    //   domain: channel.channelDomain,
    // })

    // if (
    //   scriptVerify &&
    //   scriptVerify.status === 'ok' &&
    //   scriptVerify.presence
    // ) {
    //   localStorage.setItem(activityKey, '1')
    //   return true
    // }

    if (channelStats && !isBlank(channelStats.data)) {
      const channelStat = channelStats.data.find(
        (item) => item.channel.channelUuid === channel.channelUuid
      )
      if (!channelStat) continue

      const flattenStats = [
        ...Object.values(channelStat.discover),
        ...Object.values(channelStat.statTotals),
        ...Object.values(channelStat.clicksInsight),
      ]
      if (flattenStats.some((stat) => typeof stat === 'number' && stat > 0)) {
        localStorage.setItem(activityKey, '1')
        return true
      }
    }
  }

  return false
}

export const checkAccountConversions = async (): Promise<boolean> => {
  if (localStorage.getItem(conversionKey)) return true

  const lastCheckTime = localStorage.getItem(lastConversionCheckKey)
  if (lastCheckTime && Date.now() - parseInt(lastCheckTime) < checkExpireTime) {
    return false
  }

  localStorage.setItem(lastConversionCheckKey, Date.now().toString())

  const activity = await postRequest('/insights/v1/activity', {
    fromDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  })

  if (activity?.data?.isActive) {
    localStorage.setItem(conversionKey, '1')
    return true
  } else {
    return false
  }
}

export const resetAccountChecks = () => {
  // localStorage.removeItem(activityKey)
  // localStorage.removeItem(lastActivityCheckKey)
  // localStorage.removeItem(conversionKey)
  // localStorage.removeItem(lastConversionCheckKey)
}
