export const downloadCsv = <T>(opts: {
  filename: string
  columns: (keyof T)[]
  rows: T[]
  columnFormatter: (string) => string
  rowFormatter: (T) => T
}) => {
  const { filename, columns, rows, columnFormatter, rowFormatter } = opts

  let output = ''
  let line: string[] = []

  for (const column of columns as any) {
    line.push(columnFormatter(column))
  }
  output += line.join(',') + '\n'

  for (const row of rows as any) {
    const { grp, name } = row
    const values = rowFormatter(row)

    line = []
    for (const column of columns as any) {
      if (column === 'name') {
        const nameValue = name || grp || 'Unknown'
        if (nameValue.match(/^\d+-\d+-\d+ \d+$/)) {
          line.push(nameValue + ':00')
        } else {
          line.push(nameValue)
        }
      } else {
        line.push(values[column])
      }
    }

    output +=
      line
        .map((item: string | number) => {
          if (item === null || item === undefined) {
            item = ''
          }
          if (
            item.toString().indexOf(',') === -1 &&
            item.toString().indexOf('"') === -1
          ) {
            return item.toString()
          } else {
            return `"${item.toString().replace(/"/g, '""')}"`
          }
        })
        .join(',') + '\n'
  }

  const a = document.body.appendChild(document.createElement('a'))
  a.download = filename
  a.href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(output)
  a.click()
  a.remove()
}
