export const currencies = ['DKK', 'EUR', 'GBP', 'NOK', 'SEK', 'USD', 'AUD', 'AED', 'PLN']
export const dotSeparatorCurrencies = ['GBP', 'USD', 'AUD']

export const currencyNetworkConstraints = {
  'kelkoo': ['EUR', 'GBP', 'USD'],
}
// Array.filter helper
export const withCurrencyNetworkConstraint =
  (networkIdentifier: string) =>
  (currency: string): boolean =>
    currencyNetworkConstraints[networkIdentifier]
      ? currencyNetworkConstraints[networkIdentifier].includes(currency)
      : true

export const countries = {
  'DK': 'Denmark',
  'SE': 'Sweden',
  'NO': 'Norway',
  'GB': 'United Kingdom',
  'DE': 'Germany',
  'FR': 'France',
  'BF': 'Burkina Faso',
  'BG': 'Bulgaria',
  'BH': 'Bahrain',
  'CO': 'Colombia',
  'CR': 'Costa Rica',
  'CV': 'Cape Verde',
  'ET': 'Ethiopia',
  'FI': 'Finland',
  'GR': 'Greece',
  'HK': 'Hong Kong',
  'Id': 'Indonesia',
  'IL': 'Israel',
  'IS': 'Iceland',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'KZ': 'Kazakhstan',
  'LR': 'Liberia',
  'LY': 'Libya',
  'MD': 'Republic of Moldova',
  'ML': 'Mali',
  'NZ': 'New Zealand',
  'PA': 'Panama',
  'PE': 'Peru',
  'PR': 'Puerto Rico',
  'SA': 'Saudi Arabia',
  'SC': 'Seychelles',
  'SG': 'Singapore',
  'AF': 'Afghanistan',
  'AO': 'Angola',
  'AR': 'Argentina',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BD': 'Bangladesh',
  'BJ': 'Benin',
  'BO': 'Bolivia',
  'BV': 'Bouvet Island',
  'CH': 'Switzerland',
  'CY': 'Cyprus',
  'CZ': 'Czech Republic',
  'DZ': 'Algeria',
  'EC': 'Ecuador',
  'EE': 'Estonia',
  'ES': 'Spain',
  'HR': 'Croatia',
  'IN': 'India',
  'IR': 'Iran',
  'IT': 'Italy',
  'KH': 'Cambodia',
  'MK': 'Macedonia',
  'MY': 'Malaysia',
  'NL': 'Netherlands',
  'NP': 'Nepal',
  'PH': 'Philippines',
  'PK': 'Pakistan',
  'PT': 'Portugal',
  'QA': 'Qatar',
  'SD': 'Sudan',
  'SI': 'Slovenia',
  'SK': 'Slovakia',
  'SN': 'Senegal',
  'SX': 'Sint Maarten',
  'TR': 'Turkey',
  'TW': 'Taiwan',
  'UA': 'Ukraine',
  'ZA': 'South Africa',
  'ZW': 'Zimbabwe',
  'AE': 'United Arab Emirates',
  'AM': 'Armenia',
  'BA': 'Bosnia and Herzegovina',
  'BE': 'Belgium',
  'BR': 'Brazil',
  'CA': 'Canada',
  'CI': 'Ivory Coast',
  'CN': 'China',
  'FO': 'Faroe Islands',
  'GH': 'Ghana',
  'GT': 'Guatemala',
  'HN': 'Honduras',
  'HU': 'Hungary',
  'IQ': 'Iraq',
  'KE': 'Kenya',
  'KW': 'Kuwait',
  'KY': 'Cayman Islands',
  'LB': 'Lebanon',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'MA': 'Morocco',
  'MC': 'Monaco',
  'MU': 'Mauritius',
  'MV': 'Maldives',
  'NA': 'Namibia',
  'NG': 'Nigeria',
  'OM': 'Oman',
  'RO': 'Romania',
  'RS': 'Serbia',
  'RU': 'Russia',
  'SO': 'Somalia',
  'SY': 'Syrian Arab Republic',
  'TH': 'Thailand',
  'TT': 'Trinidad and Tobago',
  'TZ': 'Tanzania',
  'UG': 'Uganda',
  'UN': 'Global',
  'US': 'United States',
  'VE': 'Venezuela',
  'VN': 'Vietnam',
  'AL': 'Albania',
  'AU': 'Australia',
  'BY': 'Belarus',
  'BZ': 'Belize',
  'CL': 'Chile',
  'DO': 'Dominican Republic',
  'EG': 'Egypt',
  'GE': 'Georgia',
  'GI': 'Gibraltar',
  'GL': 'Greenland',
  'GM': 'Gambia',
  'GY': 'Guyana',
  'IE': 'Ireland',
  'JO': 'Jordan',
  'KM': 'Comoros',
  'KR': 'Republic of Korea',
  'LA': 'Laos',
  'LK': 'Sri Lanka',
  'LV': 'Latvia',
  'ME': 'Montenegro',
  'MM': 'Myanmar',
  'MT': 'Malta',
  'MX': 'Mexico',
  'PL': 'Poland',
  'SL': 'Sierra Leone',
  'TN': 'Tunisia',
}

export const countryPhoneCodes = {
  'DK': '45',
  'SE': '46',
  'NO': '47',
  'GB': '44',
  'DE': '49',
  'FR': '33',
  'AF': '93',
  'AL': '355',
  'DZ': '213',
  'AS': '1-684',
  'AD': '376',
  'AO': '244',
  'AI': '1-264',
  'AQ': '672',
  'AG': '1-268',
  'AR': '54',
  'AM': '374',
  'AW': '297',
  'AU': '61',
  'AT': '43',
  'AZ': '994',
  'BS': '1-242',
  'BH': '973',
  'BD': '880',
  'BB': '1-246',
  'BY': '375',
  'BE': '32',
  'BZ': '501',
  'BJ': '229',
  'BM': '1-441',
  'BT': '975',
  'BO': '591',
  'BA': '387',
  'BW': '267',
  'BR': '55',
  'IO': '246',
  'VG': '1-284',
  'BN': '673',
  'BG': '359',
  'BF': '226',
  'BI': '257',
  'KH': '855',
  'CM': '237',
  'CA': '1',
  'CV': '238',
  'KY': '1-345',
  'CF': '236',
  'TD': '235',
  'CL': '56',
  'CN': '86',
  'CX': '61',
  'CC': '61',
  'CO': '57',
  'KM': '269',
  'CK': '682',
  'CR': '506',
  'HR': '385',
  'CU': '53',
  'CW': '599',
  'CY': '357',
  'CZ': '420',
  'CD': '243',
  'DJ': '253',
  'DM': '1-767',
  'DO1': '1-809',
  'DO2': '1-829',
  'DO3': '1-849',
  'TL': '670',
  'EC': '593',
  'EG': '20',
  'SV': '503',
  'GQ': '240',
  'ER': '291',
  'EE': '372',
  'ET': '251',
  'FK': '500',
  'FO': '298',
  'FJ': '679',
  'FI': '358',
  'PF': '689',
  'GA': '241',
  'GM': '220',
  'GE': '995',
  'GH': '233',
  'GI': '350',
  'GR': '30',
  'GL': '299',
  'GD': '1-473',
  'GU': '1-671',
  'GT': '502',
  'GG': '44-1481',
  'GN': '224',
  'GW': '245',
  'GY': '592',
  'HT': '509',
  'HN': '504',
  'HK': '852',
  'HU': '36',
  'IS': '354',
  'IN': '91',
  'ID': '62',
  'IR': '98',
  'IQ': '964',
  'IE': '353',
  'IM': '44-1624',
  'IL': '972',
  'IT': '39',
  'CI': '225',
  'JM': '1-876',
  'JP': '81',
  'JE': '44-1534',
  'JO': '962',
  'KZ': '7',
  'KE': '254',
  'KI': '686',
  'XK': '383',
  'KW': '965',
  'KG': '996',
  'LA': '856',
  'LV': '371',
  'LB': '961',
  'LS': '266',
  'LR': '231',
  'LY': '218',
  'LI': '423',
  'LT': '370',
  'LU': '352',
  'MO': '853',
  'MK': '389',
  'MG': '261',
  'MW': '265',
  'MY': '60',
  'MV': '960',
  'ML': '223',
  'MT': '356',
  'MH': '692',
  'MR': '222',
  'MU': '230',
  'YT': '262',
  'MX': '52',
  'FM': '691',
  'MD': '373',
  'MC': '377',
  'MN': '976',
  'ME': '382',
  'MS': '1-664',
  'MA': '212',
  'MZ': '258',
  'MM': '95',
  'NA': '264',
  'NR': '674',
  'NP': '977',
  'NL': '31',
  'AN': '599',
  'NC': '687',
  'NZ': '64',
  'NI': '505',
  'NE': '227',
  'NG': '234',
  'NU': '683',
  'KP': '850',
  'MP': '1-670',
  'OM': '968',
  'PK': '92',
  'PW': '680',
  'PS': '970',
  'PA': '507',
  'PG': '675',
  'PY': '595',
  'PE': '51',
  'PH': '63',
  'PN': '64',
  'PL': '48',
  'PT': '351',
  'PR1': '1-787',
  'PR2': '1-939',
  'QA': '974',
  'CG': '242',
  'RE': '262',
  'RO': '40',
  'RU': '7',
  'RW': '250',
  'BL': '590',
  'SH': '290',
  'KN': '1-869',
  'LC': '1-758',
  'MF': '590',
  'PM': '508',
  'VC': '1-784',
  'WS': '685',
  'SM': '378',
  'ST': '239',
  'SA': '966',
  'SN': '221',
  'RS': '381',
  'SC': '248',
  'SL': '232',
  'SG': '65',
  'SX': '1-721',
  'SK': '421',
  'SI': '386',
  'SB': '677',
  'SO': '252',
  'ZA': '27',
  'KR': '82',
  'SS': '211',
  'ES': '34',
  'LK': '94',
  'SD': '249',
  'SR': '597',
  'SJ': '47',
  'SZ': '268',
  'CH': '41',
  'SY': '963',
  'TW': '886',
  'TJ': '992',
  'TZ': '255',
  'TH': '66',
  'TG': '228',
  'TK': '690',
  'TO': '676',
  'TT': '1-868',
  'TN': '216',
  'TR': '90',
  'TM': '993',
  'TC': '1-649',
  'TV': '688',
  'VI': '1-340',
  'UG': '256',
  'UA': '380',
  'AE': '971',
  'US': '1',
  'UY': '598',
  'UZ': '998',
  'VU': '678',
  'VA': '379',
  'VE': '58',
  'VN': '84',
  'WF': '681',
  'EH': '212',
  'YE': '967',
  'ZM': '260',
  'ZW': '263',
}

export const shortMonths = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}

export const timezones = {
  'Africa/Abidjan': 'Africa/Abidjan',
  'Africa/Accra': 'Africa/Accra',
  'Africa/Addis_Ababa': 'Africa/Addis Ababa',
  'Africa/Algiers': 'Africa/Algiers',
  'Africa/Asmara': 'Africa/Asmara',
  'Africa/Asmera': 'Africa/Asmera',
  'Africa/Bamako': 'Africa/Bamako',
  'Africa/Bangui': 'Africa/Bangui',
  'Africa/Banjul': 'Africa/Banjul',
  'Africa/Bissau': 'Africa/Bissau',
  'Africa/Blantyre': 'Africa/Blantyre',
  'Africa/Brazzaville': 'Africa/Brazzaville',
  'Africa/Bujumbura': 'Africa/Bujumbura',
  'Africa/Cairo': 'Africa/Cairo',
  'Africa/Casablanca': 'Africa/Casablanca',
  'Africa/Ceuta': 'Africa/Ceuta',
  'Africa/Conakry': 'Africa/Conakry',
  'Africa/Dakar': 'Africa/Dakar',
  'Africa/Dar_es_Salaam': 'Africa/Dar es Salaam',
  'Africa/Djibouti': 'Africa/Djibouti',
  'Africa/Douala': 'Africa/Douala',
  'Africa/El_Aaiun': 'Africa/El Aaiun',
  'Africa/Freetown': 'Africa/Freetown',
  'Africa/Gaborone': 'Africa/Gaborone',
  'Africa/Harare': 'Africa/Harare',
  'Africa/Johannesburg': 'Africa/Johannesburg',
  'Africa/Juba': 'Africa/Juba',
  'Africa/Kampala': 'Africa/Kampala',
  'Africa/Khartoum': 'Africa/Khartoum',
  'Africa/Kigali': 'Africa/Kigali',
  'Africa/Kinshasa': 'Africa/Kinshasa',
  'Africa/Lagos': 'Africa/Lagos',
  'Africa/Libreville': 'Africa/Libreville',
  'Africa/Lome': 'Africa/Lome',
  'Africa/Luanda': 'Africa/Luanda',
  'Africa/Lubumbashi': 'Africa/Lubumbashi',
  'Africa/Lusaka': 'Africa/Lusaka',
  'Africa/Malabo': 'Africa/Malabo',
  'Africa/Maputo': 'Africa/Maputo',
  'Africa/Maseru': 'Africa/Maseru',
  'Africa/Mbabane': 'Africa/Mbabane',
  'Africa/Mogadishu': 'Africa/Mogadishu',
  'Africa/Monrovia': 'Africa/Monrovia',
  'Africa/Nairobi': 'Africa/Nairobi',
  'Africa/Ndjamena': 'Africa/Ndjamena',
  'Africa/Niamey': 'Africa/Niamey',
  'Africa/Nouakchott': 'Africa/Nouakchott',
  'Africa/Ouagadougou': 'Africa/Ouagadougou',
  'Africa/Porto-Novo': 'Africa/Porto-Novo',
  'Africa/Sao_Tome': 'Africa/Sao Tome',
  'Africa/Timbuktu': 'Africa/Timbuktu',
  'Africa/Tripoli': 'Africa/Tripoli',
  'Africa/Tunis': 'Africa/Tunis',
  'Africa/Windhoek': 'Africa/Windhoek',
  'America/Adak': 'America/Adak',
  'America/Anchorage': 'America/Anchorage',
  'America/Anguilla': 'America/Anguilla',
  'America/Antigua': 'America/Antigua',
  'America/Araguaina': 'America/Araguaina',
  'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos Aires',
  'America/Argentina/Catamarca': 'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia': 'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba': 'America/Argentina/Cordoba',
  'America/Argentina/Jujuy': 'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja': 'America/Argentina/La Rioja',
  'America/Argentina/Mendoza': 'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos': 'America/Argentina/Rio Gallegos',
  'America/Argentina/Salta': 'America/Argentina/Salta',
  'America/Argentina/San_Juan': 'America/Argentina/San Juan',
  'America/Argentina/San_Luis': 'America/Argentina/San Luis',
  'America/Argentina/Tucuman': 'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia': 'America/Argentina/Ushuaia',
  'America/Aruba': 'America/Aruba',
  'America/Asuncion': 'America/Asuncion',
  'America/Atikokan': 'America/Atikokan',
  'America/Atka': 'America/Atka',
  'America/Bahia': 'America/Bahia',
  'America/Bahia_Banderas': 'America/Bahia Banderas',
  'America/Barbados': 'America/Barbados',
  'America/Belem': 'America/Belem',
  'America/Belize': 'America/Belize',
  'America/Blanc-Sablon': 'America/Blanc-Sablon',
  'America/Boa_Vista': 'America/Boa Vista',
  'America/Bogota': 'America/Bogota',
  'America/Boise': 'America/Boise',
  'America/Buenos_Aires': 'America/Buenos Aires',
  'America/Cambridge_Bay': 'America/Cambridge Bay',
  'America/Campo_Grande': 'America/Campo Grande',
  'America/Cancun': 'America/Cancun',
  'America/Caracas': 'America/Caracas',
  'America/Catamarca': 'America/Catamarca',
  'America/Cayenne': 'America/Cayenne',
  'America/Cayman': 'America/Cayman',
  'America/Chicago': 'America/Chicago',
  'America/Chihuahua': 'America/Chihuahua',
  'America/Ciudad_Juarez': 'America/Ciudad Juarez',
  'America/Coral_Harbour': 'America/Coral Harbour',
  'America/Cordoba': 'America/Cordoba',
  'America/Costa_Rica': 'America/Costa Rica',
  'America/Creston': 'America/Creston',
  'America/Cuiaba': 'America/Cuiaba',
  'America/Curacao': 'America/Curacao',
  'America/Danmarkshavn': 'America/Danmarkshavn',
  'America/Dawson': 'America/Dawson',
  'America/Dawson_Creek': 'America/Dawson Creek',
  'America/Denver': 'America/Denver',
  'America/Detroit': 'America/Detroit',
  'America/Dominica': 'America/Dominica',
  'America/Edmonton': 'America/Edmonton',
  'America/Eirunepe': 'America/Eirunepe',
  'America/El_Salvador': 'America/El Salvador',
  'America/Ensenada': 'America/Ensenada',
  'America/Fort_Nelson': 'America/Fort Nelson',
  'America/Fort_Wayne': 'America/Fort Wayne',
  'America/Fortaleza': 'America/Fortaleza',
  'America/Glace_Bay': 'America/Glace Bay',
  'America/Godthab': 'America/Godthab',
  'America/Goose_Bay': 'America/Goose Bay',
  'America/Grand_Turk': 'America/Grand Turk',
  'America/Grenada': 'America/Grenada',
  'America/Guadeloupe': 'America/Guadeloupe',
  'America/Guatemala': 'America/Guatemala',
  'America/Guayaquil': 'America/Guayaquil',
  'America/Guyana': 'America/Guyana',
  'America/Halifax': 'America/Halifax',
  'America/Havana': 'America/Havana',
  'America/Hermosillo': 'America/Hermosillo',
  'America/Indiana/Indianapolis': 'America/Indiana/Indianapolis',
  'America/Indiana/Knox': 'America/Indiana/Knox',
  'America/Indiana/Marengo': 'America/Indiana/Marengo',
  'America/Indiana/Petersburg': 'America/Indiana/Petersburg',
  'America/Indiana/Tell_City': 'America/Indiana/Tell City',
  'America/Indiana/Vevay': 'America/Indiana/Vevay',
  'America/Indiana/Vincennes': 'America/Indiana/Vincennes',
  'America/Indiana/Winamac': 'America/Indiana/Winamac',
  'America/Indianapolis': 'America/Indianapolis',
  'America/Inuvik': 'America/Inuvik',
  'America/Iqaluit': 'America/Iqaluit',
  'America/Jamaica': 'America/Jamaica',
  'America/Jujuy': 'America/Jujuy',
  'America/Juneau': 'America/Juneau',
  'America/Kentucky/Louisville': 'America/Kentucky/Louisville',
  'America/Kentucky/Monticello': 'America/Kentucky/Monticello',
  'America/Knox_IN': 'America/Knox IN',
  'America/Kralendijk': 'America/Kralendijk',
  'America/La_Paz': 'America/La Paz',
  'America/Lima': 'America/Lima',
  'America/Los_Angeles': 'America/Los Angeles',
  'America/Louisville': 'America/Louisville',
  'America/Lower_Princes': 'America/Lower Princes',
  'America/Maceio': 'America/Maceio',
  'America/Managua': 'America/Managua',
  'America/Manaus': 'America/Manaus',
  'America/Marigot': 'America/Marigot',
  'America/Martinique': 'America/Martinique',
  'America/Matamoros': 'America/Matamoros',
  'America/Mazatlan': 'America/Mazatlan',
  'America/Mendoza': 'America/Mendoza',
  'America/Menominee': 'America/Menominee',
  'America/Merida': 'America/Merida',
  'America/Metlakatla': 'America/Metlakatla',
  'America/Mexico_City': 'America/Mexico City',
  'America/Miquelon': 'America/Miquelon',
  'America/Moncton': 'America/Moncton',
  'America/Monterrey': 'America/Monterrey',
  'America/Montevideo': 'America/Montevideo',
  'America/Montreal': 'America/Montreal',
  'America/Montserrat': 'America/Montserrat',
  'America/Nassau': 'America/Nassau',
  'America/New_York': 'America/New York',
  'America/Nipigon': 'America/Nipigon',
  'America/Nome': 'America/Nome',
  'America/Noronha': 'America/Noronha',
  'America/North_Dakota/Beulah': 'America/North Dakota/Beulah',
  'America/North_Dakota/Center': 'America/North Dakota/Center',
  'America/North_Dakota/New_Salem': 'America/North Dakota/New Salem',
  'America/Nuuk': 'America/Nuuk',
  'America/Ojinaga': 'America/Ojinaga',
  'America/Panama': 'America/Panama',
  'America/Pangnirtung': 'America/Pangnirtung',
  'America/Paramaribo': 'America/Paramaribo',
  'America/Phoenix': 'America/Phoenix',
  'America/Port-au-Prince': 'America/Port-au-Prince',
  'America/Port_of_Spain': 'America/Port of Spain',
  'America/Porto_Acre': 'America/Porto Acre',
  'America/Porto_Velho': 'America/Porto Velho',
  'America/Puerto_Rico': 'America/Puerto Rico',
  'America/Punta_Arenas': 'America/Punta Arenas',
  'America/Rainy_River': 'America/Rainy River',
  'America/Rankin_Inlet': 'America/Rankin Inlet',
  'America/Recife': 'America/Recife',
  'America/Regina': 'America/Regina',
  'America/Resolute': 'America/Resolute',
  'America/Rio_Branco': 'America/Rio Branco',
  'America/Rosario': 'America/Rosario',
  'America/Santa_Isabel': 'America/Santa Isabel',
  'America/Santarem': 'America/Santarem',
  'America/Santiago': 'America/Santiago',
  'America/Santo_Domingo': 'America/Santo Domingo',
  'America/Sao_Paulo': 'America/Sao Paulo',
  'America/Scoresbysund': 'America/Scoresbysund',
  'America/Shiprock': 'America/Shiprock',
  'America/Sitka': 'America/Sitka',
  'America/St_Barthelemy': 'America/St Barthelemy',
  'America/St_Johns': 'America/St Johns',
  'America/St_Kitts': 'America/St Kitts',
  'America/St_Lucia': 'America/St Lucia',
  'America/St_Thomas': 'America/St Thomas',
  'America/St_Vincent': 'America/St Vincent',
  'America/Swift_Current': 'America/Swift Current',
  'America/Tegucigalpa': 'America/Tegucigalpa',
  'America/Thule': 'America/Thule',
  'America/Thunder_Bay': 'America/Thunder Bay',
  'America/Tijuana': 'America/Tijuana',
  'America/Toronto': 'America/Toronto',
  'America/Tortola': 'America/Tortola',
  'America/Vancouver': 'America/Vancouver',
  'America/Virgin': 'America/Virgin',
  'America/Whitehorse': 'America/Whitehorse',
  'America/Winnipeg': 'America/Winnipeg',
  'America/Yakutat': 'America/Yakutat',
  'America/Yellowknife': 'America/Yellowknife',
  'Antarctica/Casey': 'Antarctica/Casey',
  'Antarctica/Davis': 'Antarctica/Davis',
  'Antarctica/DumontDUrville': 'Antarctica/DumontDUrville',
  'Antarctica/Macquarie': 'Antarctica/Macquarie',
  'Antarctica/Mawson': 'Antarctica/Mawson',
  'Antarctica/McMurdo': 'Antarctica/McMurdo',
  'Antarctica/Palmer': 'Antarctica/Palmer',
  'Antarctica/Rothera': 'Antarctica/Rothera',
  'Antarctica/South_Pole': 'Antarctica/South Pole',
  'Antarctica/Syowa': 'Antarctica/Syowa',
  'Antarctica/Troll': 'Antarctica/Troll',
  'Antarctica/Vostok': 'Antarctica/Vostok',
  'Arctic/Longyearbyen': 'Arctic/Longyearbyen',
  'Asia/Aden': 'Asia/Aden',
  'Asia/Almaty': 'Asia/Almaty',
  'Asia/Amman': 'Asia/Amman',
  'Asia/Anadyr': 'Asia/Anadyr',
  'Asia/Aqtau': 'Asia/Aqtau',
  'Asia/Aqtobe': 'Asia/Aqtobe',
  'Asia/Ashgabat': 'Asia/Ashgabat',
  'Asia/Ashkhabad': 'Asia/Ashkhabad',
  'Asia/Atyrau': 'Asia/Atyrau',
  'Asia/Baghdad': 'Asia/Baghdad',
  'Asia/Bahrain': 'Asia/Bahrain',
  'Asia/Baku': 'Asia/Baku',
  'Asia/Bangkok': 'Asia/Bangkok',
  'Asia/Barnaul': 'Asia/Barnaul',
  'Asia/Beirut': 'Asia/Beirut',
  'Asia/Bishkek': 'Asia/Bishkek',
  'Asia/Brunei': 'Asia/Brunei',
  'Asia/Calcutta': 'Asia/Calcutta',
  'Asia/Chita': 'Asia/Chita',
  'Asia/Choibalsan': 'Asia/Choibalsan',
  'Asia/Chongqing': 'Asia/Chongqing',
  'Asia/Chungking': 'Asia/Chungking',
  'Asia/Colombo': 'Asia/Colombo',
  'Asia/Dacca': 'Asia/Dacca',
  'Asia/Damascus': 'Asia/Damascus',
  'Asia/Dhaka': 'Asia/Dhaka',
  'Asia/Dili': 'Asia/Dili',
  'Asia/Dubai': 'Asia/Dubai',
  'Asia/Dushanbe': 'Asia/Dushanbe',
  'Asia/Famagusta': 'Asia/Famagusta',
  'Asia/Gaza': 'Asia/Gaza',
  'Asia/Harbin': 'Asia/Harbin',
  'Asia/Hebron': 'Asia/Hebron',
  'Asia/Ho_Chi_Minh': 'Asia/Ho Chi Minh',
  'Asia/Hong_Kong': 'Asia/Hong Kong',
  'Asia/Hovd': 'Asia/Hovd',
  'Asia/Irkutsk': 'Asia/Irkutsk',
  'Asia/Istanbul': 'Asia/Istanbul',
  'Asia/Jakarta': 'Asia/Jakarta',
  'Asia/Jayapura': 'Asia/Jayapura',
  'Asia/Jerusalem': 'Asia/Jerusalem',
  'Asia/Kabul': 'Asia/Kabul',
  'Asia/Kamchatka': 'Asia/Kamchatka',
  'Asia/Karachi': 'Asia/Karachi',
  'Asia/Kashgar': 'Asia/Kashgar',
  'Asia/Kathmandu': 'Asia/Kathmandu',
  'Asia/Katmandu': 'Asia/Katmandu',
  'Asia/Khandyga': 'Asia/Khandyga',
  'Asia/Kolkata': 'Asia/Kolkata',
  'Asia/Krasnoyarsk': 'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Asia/Kuala Lumpur',
  'Asia/Kuching': 'Asia/Kuching',
  'Asia/Kuwait': 'Asia/Kuwait',
  'Asia/Macao': 'Asia/Macao',
  'Asia/Macau': 'Asia/Macau',
  'Asia/Magadan': 'Asia/Magadan',
  'Asia/Makassar': 'Asia/Makassar',
  'Asia/Manila': 'Asia/Manila',
  'Asia/Muscat': 'Asia/Muscat',
  'Asia/Nicosia': 'Asia/Nicosia',
  'Asia/Novokuznetsk': 'Asia/Novokuznetsk',
  'Asia/Novosibirsk': 'Asia/Novosibirsk',
  'Asia/Omsk': 'Asia/Omsk',
  'Asia/Oral': 'Asia/Oral',
  'Asia/Phnom_Penh': 'Asia/Phnom Penh',
  'Asia/Pontianak': 'Asia/Pontianak',
  'Asia/Pyongyang': 'Asia/Pyongyang',
  'Asia/Qatar': 'Asia/Qatar',
  'Asia/Qostanay': 'Asia/Qostanay',
  'Asia/Qyzylorda': 'Asia/Qyzylorda',
  'Asia/Rangoon': 'Asia/Rangoon',
  'Asia/Riyadh': 'Asia/Riyadh',
  'Asia/Saigon': 'Asia/Saigon',
  'Asia/Sakhalin': 'Asia/Sakhalin',
  'Asia/Samarkand': 'Asia/Samarkand',
  'Asia/Seoul': 'Asia/Seoul',
  'Asia/Shanghai': 'Asia/Shanghai',
  'Asia/Singapore': 'Asia/Singapore',
  'Asia/Srednekolymsk': 'Asia/Srednekolymsk',
  'Asia/Taipei': 'Asia/Taipei',
  'Asia/Tashkent': 'Asia/Tashkent',
  'Asia/Tbilisi': 'Asia/Tbilisi',
  'Asia/Tehran': 'Asia/Tehran',
  'Asia/Tel_Aviv': 'Asia/Tel Aviv',
  'Asia/Thimbu': 'Asia/Thimbu',
  'Asia/Thimphu': 'Asia/Thimphu',
  'Asia/Tokyo': 'Asia/Tokyo',
  'Asia/Tomsk': 'Asia/Tomsk',
  'Asia/Ujung_Pandang': 'Asia/Ujung Pandang',
  'Asia/Ulaanbaatar': 'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator': 'Asia/Ulan Bator',
  'Asia/Urumqi': 'Asia/Urumqi',
  'Asia/Ust-Nera': 'Asia/Ust-Nera',
  'Asia/Vientiane': 'Asia/Vientiane',
  'Asia/Vladivostok': 'Asia/Vladivostok',
  'Asia/Yakutsk': 'Asia/Yakutsk',
  'Asia/Yangon': 'Asia/Yangon',
  'Asia/Yekaterinburg': 'Asia/Yekaterinburg',
  'Asia/Yerevan': 'Asia/Yerevan',
  'Atlantic/Azores': 'Atlantic/Azores',
  'Atlantic/Bermuda': 'Atlantic/Bermuda',
  'Atlantic/Canary': 'Atlantic/Canary',
  'Atlantic/Cape_Verde': 'Atlantic/Cape Verde',
  'Atlantic/Faeroe': 'Atlantic/Faeroe',
  'Atlantic/Faroe': 'Atlantic/Faroe',
  'Atlantic/Jan_Mayen': 'Atlantic/Jan Mayen',
  'Atlantic/Madeira': 'Atlantic/Madeira',
  'Atlantic/Reykjavik': 'Atlantic/Reykjavik',
  'Atlantic/South_Georgia': 'Atlantic/South Georgia',
  'Atlantic/St_Helena': 'Atlantic/St Helena',
  'Atlantic/Stanley': 'Atlantic/Stanley',
  'Australia/ACT': 'Australia/ACT',
  'Australia/Adelaide': 'Australia/Adelaide',
  'Australia/Brisbane': 'Australia/Brisbane',
  'Australia/Broken_Hill': 'Australia/Broken Hill',
  'Australia/Canberra': 'Australia/Canberra',
  'Australia/Currie': 'Australia/Currie',
  'Australia/Darwin': 'Australia/Darwin',
  'Australia/Eucla': 'Australia/Eucla',
  'Australia/Hobart': 'Australia/Hobart',
  'Australia/LHI': 'Australia/LHI',
  'Australia/Lindeman': 'Australia/Lindeman',
  'Australia/Lord_Howe': 'Australia/Lord Howe',
  'Australia/Melbourne': 'Australia/Melbourne',
  'Australia/NSW': 'Australia/NSW',
  'Australia/North': 'Australia/North',
  'Australia/Perth': 'Australia/Perth',
  'Australia/Queensland': 'Australia/Queensland',
  'Australia/South': 'Australia/South',
  'Australia/Sydney': 'Australia/Sydney',
  'Australia/Tasmania': 'Australia/Tasmania',
  'Australia/Victoria': 'Australia/Victoria',
  'Australia/West': 'Australia/West',
  'Australia/Yancowinna': 'Australia/Yancowinna',
  'Brazil/Acre': 'Brazil/Acre',
  'Brazil/DeNoronha': 'Brazil/DeNoronha',
  'Brazil/East': 'Brazil/East',
  'Brazil/West': 'Brazil/West',
  'Canada/Atlantic': 'Canada/Atlantic',
  'Canada/Central': 'Canada/Central',
  'Canada/Eastern': 'Canada/Eastern',
  'Canada/Mountain': 'Canada/Mountain',
  'Canada/Newfoundland': 'Canada/Newfoundland',
  'Canada/Pacific': 'Canada/Pacific',
  'Canada/Saskatchewan': 'Canada/Saskatchewan',
  'Canada/Yukon': 'Canada/Yukon',
  'Chile/Continental': 'Chile/Continental',
  'Chile/EasterIsland': 'Chile/EasterIsland',
  'Europe/Amsterdam': 'Europe/Amsterdam',
  'Europe/Andorra': 'Europe/Andorra',
  'Europe/Astrakhan': 'Europe/Astrakhan',
  'Europe/Athens': 'Europe/Athens',
  'Europe/Belfast': 'Europe/Belfast',
  'Europe/Belgrade': 'Europe/Belgrade',
  'Europe/Berlin': 'Europe/Berlin',
  'Europe/Bratislava': 'Europe/Bratislava',
  'Europe/Brussels': 'Europe/Brussels',
  'Europe/Bucharest': 'Europe/Bucharest',
  'Europe/Budapest': 'Europe/Budapest',
  'Europe/Busingen': 'Europe/Busingen',
  'Europe/Chisinau': 'Europe/Chisinau',
  'Europe/Copenhagen': 'Europe/Copenhagen',
  'Europe/Dublin': 'Europe/Dublin',
  'Europe/Gibraltar': 'Europe/Gibraltar',
  'Europe/Guernsey': 'Europe/Guernsey',
  'Europe/Helsinki': 'Europe/Helsinki',
  'Europe/Isle_of_Man': 'Europe/Isle of Man',
  'Europe/Istanbul': 'Europe/Istanbul',
  'Europe/Jersey': 'Europe/Jersey',
  'Europe/Kaliningrad': 'Europe/Kaliningrad',
  'Europe/Kiev': 'Europe/Kiev',
  'Europe/Kirov': 'Europe/Kirov',
  'Europe/Kyiv': 'Europe/Kyiv',
  'Europe/Lisbon': 'Europe/Lisbon',
  'Europe/Ljubljana': 'Europe/Ljubljana',
  'Europe/London': 'Europe/London',
  'Europe/Luxembourg': 'Europe/Luxembourg',
  'Europe/Madrid': 'Europe/Madrid',
  'Europe/Malta': 'Europe/Malta',
  'Europe/Mariehamn': 'Europe/Mariehamn',
  'Europe/Minsk': 'Europe/Minsk',
  'Europe/Monaco': 'Europe/Monaco',
  'Europe/Moscow': 'Europe/Moscow',
  'Europe/Nicosia': 'Europe/Nicosia',
  'Europe/Oslo': 'Europe/Oslo',
  'Europe/Paris': 'Europe/Paris',
  'Europe/Podgorica': 'Europe/Podgorica',
  'Europe/Prague': 'Europe/Prague',
  'Europe/Riga': 'Europe/Riga',
  'Europe/Rome': 'Europe/Rome',
  'Europe/Samara': 'Europe/Samara',
  'Europe/San_Marino': 'Europe/San Marino',
  'Europe/Sarajevo': 'Europe/Sarajevo',
  'Europe/Saratov': 'Europe/Saratov',
  'Europe/Simferopol': 'Europe/Simferopol',
  'Europe/Skopje': 'Europe/Skopje',
  'Europe/Sofia': 'Europe/Sofia',
  'Europe/Stockholm': 'Europe/Stockholm',
  'Europe/Tallinn': 'Europe/Tallinn',
  'Europe/Tirane': 'Europe/Tirane',
  'Europe/Tiraspol': 'Europe/Tiraspol',
  'Europe/Ulyanovsk': 'Europe/Ulyanovsk',
  'Europe/Uzhgorod': 'Europe/Uzhgorod',
  'Europe/Vaduz': 'Europe/Vaduz',
  'Europe/Vatican': 'Europe/Vatican',
  'Europe/Vienna': 'Europe/Vienna',
  'Europe/Vilnius': 'Europe/Vilnius',
  'Europe/Volgograd': 'Europe/Volgograd',
  'Europe/Warsaw': 'Europe/Warsaw',
  'Europe/Zagreb': 'Europe/Zagreb',
  'Europe/Zaporozhye': 'Europe/Zaporozhye',
  'Europe/Zurich': 'Europe/Zurich',
  'Indian/Antananarivo': 'Indian/Antananarivo',
  'Indian/Chagos': 'Indian/Chagos',
  'Indian/Christmas': 'Indian/Christmas',
  'Indian/Cocos': 'Indian/Cocos',
  'Indian/Comoro': 'Indian/Comoro',
  'Indian/Kerguelen': 'Indian/Kerguelen',
  'Indian/Mahe': 'Indian/Mahe',
  'Indian/Maldives': 'Indian/Maldives',
  'Indian/Mauritius': 'Indian/Mauritius',
  'Indian/Mayotte': 'Indian/Mayotte',
  'Indian/Reunion': 'Indian/Reunion',
  'Mexico/BajaNorte': 'Mexico/BajaNorte',
  'Mexico/BajaSur': 'Mexico/BajaSur',
  'Mexico/General': 'Mexico/General',
  'Pacific/Apia': 'Pacific/Apia',
  'Pacific/Auckland': 'Pacific/Auckland',
  'Pacific/Bougainville': 'Pacific/Bougainville',
  'Pacific/Chatham': 'Pacific/Chatham',
  'Pacific/Chuuk': 'Pacific/Chuuk',
  'Pacific/Easter': 'Pacific/Easter',
  'Pacific/Efate': 'Pacific/Efate',
  'Pacific/Enderbury': 'Pacific/Enderbury',
  'Pacific/Fakaofo': 'Pacific/Fakaofo',
  'Pacific/Fiji': 'Pacific/Fiji',
  'Pacific/Funafuti': 'Pacific/Funafuti',
  'Pacific/Galapagos': 'Pacific/Galapagos',
  'Pacific/Gambier': 'Pacific/Gambier',
  'Pacific/Guadalcanal': 'Pacific/Guadalcanal',
  'Pacific/Guam': 'Pacific/Guam',
  'Pacific/Honolulu': 'Pacific/Honolulu',
  'Pacific/Johnston': 'Pacific/Johnston',
  'Pacific/Kanton': 'Pacific/Kanton',
  'Pacific/Kiritimati': 'Pacific/Kiritimati',
  'Pacific/Kosrae': 'Pacific/Kosrae',
  'Pacific/Kwajalein': 'Pacific/Kwajalein',
  'Pacific/Majuro': 'Pacific/Majuro',
  'Pacific/Marquesas': 'Pacific/Marquesas',
  'Pacific/Midway': 'Pacific/Midway',
  'Pacific/Nauru': 'Pacific/Nauru',
  'Pacific/Niue': 'Pacific/Niue',
  'Pacific/Norfolk': 'Pacific/Norfolk',
  'Pacific/Noumea': 'Pacific/Noumea',
  'Pacific/Pago_Pago': 'Pacific/Pago Pago',
  'Pacific/Palau': 'Pacific/Palau',
  'Pacific/Pitcairn': 'Pacific/Pitcairn',
  'Pacific/Pohnpei': 'Pacific/Pohnpei',
  'Pacific/Ponape': 'Pacific/Ponape',
  'Pacific/Port_Moresby': 'Pacific/Port Moresby',
  'Pacific/Rarotonga': 'Pacific/Rarotonga',
  'Pacific/Saipan': 'Pacific/Saipan',
  'Pacific/Samoa': 'Pacific/Samoa',
  'Pacific/Tahiti': 'Pacific/Tahiti',
  'Pacific/Tarawa': 'Pacific/Tarawa',
  'Pacific/Tongatapu': 'Pacific/Tongatapu',
  'Pacific/Truk': 'Pacific/Truk',
  'Pacific/Wake': 'Pacific/Wake',
  'Pacific/Wallis': 'Pacific/Wallis',
  'Pacific/Yap': 'Pacific/Yap',
  'US/Alaska': 'US/Alaska',
  'US/Aleutian': 'US/Aleutian',
  'US/Arizona': 'US/Arizona',
  'US/Central': 'US/Central',
  'US/East-Indiana': 'US/East-Indiana',
  'US/Eastern': 'US/Eastern',
  'US/Hawaii': 'US/Hawaii',
  'US/Indiana-Starke': 'US/Indiana-Starke',
  'US/Michigan': 'US/Michigan',
  'US/Mountain': 'US/Mountain',
  'US/Pacific': 'US/Pacific',
  'US/Samoa': 'US/Samoa',
}

export const timezoneOffsets = {
  'Africa/Abidjan': '+00:00',
  'Africa/Accra': '+00:00',
  'Africa/Addis_Ababa': '+03:00',
  'Africa/Algiers': '+01:00',
  'Africa/Asmara': '+03:00',
  'Africa/Asmera': '+03:00',
  'Africa/Bamako': '+00:00',
  'Africa/Bangui': '+01:00',
  'Africa/Banjul': '+00:00',
  'Africa/Bissau': '+00:00',
  'Africa/Blantyre': '+02:00',
  'Africa/Brazzaville': '+01:00',
  'Africa/Bujumbura': '+02:00',
  'Africa/Cairo': '+02:00',
  'Africa/Casablanca': '+01:00',
  'Africa/Ceuta': '+01:00',
  'Africa/Conakry': '+00:00',
  'Africa/Dakar': '+00:00',
  'Africa/Dar_es_Salaam': '+03:00',
  'Africa/Djibouti': '+03:00',
  'Africa/Douala': '+01:00',
  'Africa/El_Aaiun': '+01:00',
  'Africa/Freetown': '+00:00',
  'Africa/Gaborone': '+02:00',
  'Africa/Harare': '+02:00',
  'Africa/Johannesburg': '+02:00',
  'Africa/Juba': '+02:00',
  'Africa/Kampala': '+03:00',
  'Africa/Khartoum': '+02:00',
  'Africa/Kigali': '+02:00',
  'Africa/Kinshasa': '+01:00',
  'Africa/Lagos': '+01:00',
  'Africa/Libreville': '+01:00',
  'Africa/Lome': '+00:00',
  'Africa/Luanda': '+01:00',
  'Africa/Lubumbashi': '+02:00',
  'Africa/Lusaka': '+02:00',
  'Africa/Malabo': '+01:00',
  'Africa/Maputo': '+02:00',
  'Africa/Maseru': '+02:00',
  'Africa/Mbabane': '+02:00',
  'Africa/Mogadishu': '+03:00',
  'Africa/Monrovia': '+00:00',
  'Africa/Nairobi': '+03:00',
  'Africa/Ndjamena': '+01:00',
  'Africa/Niamey': '+01:00',
  'Africa/Nouakchott': '+00:00',
  'Africa/Ouagadougou': '+00:00',
  'Africa/Porto-Novo': '+01:00',
  'Africa/Sao_Tome': '+00:00',
  'Africa/Timbuktu': '+00:00',
  'Africa/Tripoli': '+02:00',
  'Africa/Tunis': '+01:00',
  'Africa/Windhoek': '+02:00',
  'America/Adak': '-10:00',
  'America/Anchorage': '-09:00',
  'America/Anguilla': '-04:00',
  'America/Antigua': '-04:00',
  'America/Araguaina': '-03:00',
  'America/Argentina/Buenos_Aires': '-03:00',
  'America/Argentina/Catamarca': '-03:00',
  'America/Argentina/ComodRivadavia': '-03:00',
  'America/Argentina/Cordoba': '-03:00',
  'America/Argentina/Jujuy': '-03:00',
  'America/Argentina/La_Rioja': '-03:00',
  'America/Argentina/Mendoza': '-03:00',
  'America/Argentina/Rio_Gallegos': '-03:00',
  'America/Argentina/Salta': '-03:00',
  'America/Argentina/San_Juan': '-03:00',
  'America/Argentina/San_Luis': '-03:00',
  'America/Argentina/Tucuman': '-03:00',
  'America/Argentina/Ushuaia': '-03:00',
  'America/Aruba': '-04:00',
  'America/Asuncion': '-03:00',
  'America/Atikokan': '-05:00',
  'America/Atka': '-10:00',
  'America/Bahia': '-03:00',
  'America/Bahia_Banderas': '-06:00',
  'America/Barbados': '-04:00',
  'America/Belem': '-03:00',
  'America/Belize': '-06:00',
  'America/Blanc-Sablon': '-04:00',
  'America/Boa_Vista': '-04:00',
  'America/Bogota': '-05:00',
  'America/Boise': '-07:00',
  'America/Buenos_Aires': '-03:00',
  'America/Cambridge_Bay': '-07:00',
  'America/Campo_Grande': '-04:00',
  'America/Cancun': '-05:00',
  'America/Caracas': '-04:00',
  'America/Catamarca': '-03:00',
  'America/Cayenne': '-03:00',
  'America/Cayman': '-05:00',
  'America/Chicago': '-06:00',
  'America/Chihuahua': '-06:00',
  'America/Ciudad_Juarez': '-07:00',
  'America/Coral_Harbour': '-05:00',
  'America/Cordoba': '-03:00',
  'America/Costa_Rica': '-06:00',
  'America/Creston': '-07:00',
  'America/Cuiaba': '-04:00',
  'America/Curacao': '-04:00',
  'America/Danmarkshavn': '+00:00',
  'America/Dawson': '-07:00',
  'America/Dawson_Creek': '-07:00',
  'America/Denver': '-07:00',
  'America/Detroit': '-05:00',
  'America/Dominica': '-04:00',
  'America/Edmonton': '-07:00',
  'America/Eirunepe': '-05:00',
  'America/El_Salvador': '-06:00',
  'America/Ensenada': '-08:00',
  'America/Fort_Nelson': '-07:00',
  'America/Fort_Wayne': '-05:00',
  'America/Fortaleza': '-03:00',
  'America/Glace_Bay': '-04:00',
  'America/Godthab': '-02:00',
  'America/Goose_Bay': '-04:00',
  'America/Grand_Turk': '-05:00',
  'America/Grenada': '-04:00',
  'America/Guadeloupe': '-04:00',
  'America/Guatemala': '-06:00',
  'America/Guayaquil': '-05:00',
  'America/Guyana': '-04:00',
  'America/Halifax': '-04:00',
  'America/Havana': '-05:00',
  'America/Hermosillo': '-07:00',
  'America/Indiana/Indianapolis': '-05:00',
  'America/Indiana/Knox': '-06:00',
  'America/Indiana/Marengo': '-05:00',
  'America/Indiana/Petersburg': '-05:00',
  'America/Indiana/Tell_City': '-06:00',
  'America/Indiana/Vevay': '-05:00',
  'America/Indiana/Vincennes': '-05:00',
  'America/Indiana/Winamac': '-05:00',
  'America/Indianapolis': '-05:00',
  'America/Inuvik': '-07:00',
  'America/Iqaluit': '-05:00',
  'America/Jamaica': '-05:00',
  'America/Jujuy': '-03:00',
  'America/Juneau': '-09:00',
  'America/Kentucky/Louisville': '-05:00',
  'America/Kentucky/Monticello': '-05:00',
  'America/Knox_IN': '-06:00',
  'America/Kralendijk': '-04:00',
  'America/La_Paz': '-04:00',
  'America/Lima': '-05:00',
  'America/Los_Angeles': '-08:00',
  'America/Louisville': '-05:00',
  'America/Lower_Princes': '-04:00',
  'America/Maceio': '-03:00',
  'America/Managua': '-06:00',
  'America/Manaus': '-04:00',
  'America/Marigot': '-04:00',
  'America/Martinique': '-04:00',
  'America/Matamoros': '-06:00',
  'America/Mazatlan': '-07:00',
  'America/Mendoza': '-03:00',
  'America/Menominee': '-06:00',
  'America/Merida': '-06:00',
  'America/Metlakatla': '-09:00',
  'America/Mexico_City': '-06:00',
  'America/Miquelon': '-03:00',
  'America/Moncton': '-04:00',
  'America/Monterrey': '-06:00',
  'America/Montevideo': '-03:00',
  'America/Montreal': '-05:00',
  'America/Montserrat': '-04:00',
  'America/Nassau': '-05:00',
  'America/New_York': '-05:00',
  'America/Nipigon': '-05:00',
  'America/Nome': '-09:00',
  'America/Noronha': '-02:00',
  'America/North_Dakota/Beulah': '-06:00',
  'America/North_Dakota/Center': '-06:00',
  'America/North_Dakota/New_Salem': '-06:00',
  'America/Nuuk': '-02:00',
  'America/Ojinaga': '-06:00',
  'America/Panama': '-05:00',
  'America/Pangnirtung': '-05:00',
  'America/Paramaribo': '-03:00',
  'America/Phoenix': '-07:00',
  'America/Port-au-Prince': '-05:00',
  'America/Port_of_Spain': '-04:00',
  'America/Porto_Acre': '-05:00',
  'America/Porto_Velho': '-04:00',
  'America/Puerto_Rico': '-04:00',
  'America/Punta_Arenas': '-03:00',
  'America/Rainy_River': '-06:00',
  'America/Rankin_Inlet': '-06:00',
  'America/Recife': '-03:00',
  'America/Regina': '-06:00',
  'America/Resolute': '-06:00',
  'America/Rio_Branco': '-05:00',
  'America/Rosario': '-03:00',
  'America/Santa_Isabel': '-08:00',
  'America/Santarem': '-03:00',
  'America/Santiago': '-03:00',
  'America/Santo_Domingo': '-04:00',
  'America/Sao_Paulo': '-03:00',
  'America/Scoresbysund': '-01:00',
  'America/Shiprock': '-07:00',
  'America/Sitka': '-09:00',
  'America/St_Barthelemy': '-04:00',
  'America/St_Johns': '-03:30',
  'America/St_Kitts': '-04:00',
  'America/St_Lucia': '-04:00',
  'America/St_Thomas': '-04:00',
  'America/St_Vincent': '-04:00',
  'America/Swift_Current': '-06:00',
  'America/Tegucigalpa': '-06:00',
  'America/Thule': '-04:00',
  'America/Thunder_Bay': '-05:00',
  'America/Tijuana': '-08:00',
  'America/Toronto': '-05:00',
  'America/Tortola': '-04:00',
  'America/Vancouver': '-08:00',
  'America/Virgin': '-04:00',
  'America/Whitehorse': '-07:00',
  'America/Winnipeg': '-06:00',
  'America/Yakutat': '-09:00',
  'America/Yellowknife': '-07:00',
  'Antarctica/Casey': '+11:00',
  'Antarctica/Davis': '+07:00',
  'Antarctica/DumontDUrville': '+10:00',
  'Antarctica/Macquarie': '+11:00',
  'Antarctica/Mawson': '+05:00',
  'Antarctica/McMurdo': '+13:00',
  'Antarctica/Palmer': '-03:00',
  'Antarctica/Rothera': '-03:00',
  'Antarctica/South_Pole': '+13:00',
  'Antarctica/Syowa': '+03:00',
  'Antarctica/Troll': '+00:00',
  'Antarctica/Vostok': '+06:00',
  'Arctic/Longyearbyen': '+01:00',
  'Asia/Aden': '+03:00',
  'Asia/Almaty': '+06:00',
  'Asia/Amman': '+03:00',
  'Asia/Anadyr': '+12:00',
  'Asia/Aqtau': '+05:00',
  'Asia/Aqtobe': '+05:00',
  'Asia/Ashgabat': '+05:00',
  'Asia/Ashkhabad': '+05:00',
  'Asia/Atyrau': '+05:00',
  'Asia/Baghdad': '+03:00',
  'Asia/Bahrain': '+03:00',
  'Asia/Baku': '+04:00',
  'Asia/Bangkok': '+07:00',
  'Asia/Barnaul': '+07:00',
  'Asia/Beirut': '+02:00',
  'Asia/Bishkek': '+06:00',
  'Asia/Brunei': '+08:00',
  'Asia/Calcutta': '+05:30',
  'Asia/Chita': '+09:00',
  'Asia/Choibalsan': '+08:00',
  'Asia/Chongqing': '+08:00',
  'Asia/Chungking': '+08:00',
  'Asia/Colombo': '+05:30',
  'Asia/Dacca': '+06:00',
  'Asia/Damascus': '+03:00',
  'Asia/Dhaka': '+06:00',
  'Asia/Dili': '+09:00',
  'Asia/Dubai': '+04:00',
  'Asia/Dushanbe': '+05:00',
  'Asia/Famagusta': '+02:00',
  'Asia/Gaza': '+02:00',
  'Asia/Harbin': '+08:00',
  'Asia/Hebron': '+02:00',
  'Asia/Ho_Chi_Minh': '+07:00',
  'Asia/Hong_Kong': '+08:00',
  'Asia/Hovd': '+07:00',
  'Asia/Irkutsk': '+08:00',
  'Asia/Istanbul': '+03:00',
  'Asia/Jakarta': '+07:00',
  'Asia/Jayapura': '+09:00',
  'Asia/Jerusalem': '+02:00',
  'Asia/Kabul': '+04:30',
  'Asia/Kamchatka': '+12:00',
  'Asia/Karachi': '+05:00',
  'Asia/Kashgar': '+06:00',
  'Asia/Kathmandu': '+05:45',
  'Asia/Katmandu': '+05:45',
  'Asia/Khandyga': '+09:00',
  'Asia/Kolkata': '+05:30',
  'Asia/Krasnoyarsk': '+07:00',
  'Asia/Kuala_Lumpur': '+08:00',
  'Asia/Kuching': '+08:00',
  'Asia/Kuwait': '+03:00',
  'Asia/Macao': '+08:00',
  'Asia/Macau': '+08:00',
  'Asia/Magadan': '+11:00',
  'Asia/Makassar': '+08:00',
  'Asia/Manila': '+08:00',
  'Asia/Muscat': '+04:00',
  'Asia/Nicosia': '+02:00',
  'Asia/Novokuznetsk': '+07:00',
  'Asia/Novosibirsk': '+07:00',
  'Asia/Omsk': '+06:00',
  'Asia/Oral': '+05:00',
  'Asia/Phnom_Penh': '+07:00',
  'Asia/Pontianak': '+07:00',
  'Asia/Pyongyang': '+09:00',
  'Asia/Qatar': '+03:00',
  'Asia/Qostanay': '+06:00',
  'Asia/Qyzylorda': '+05:00',
  'Asia/Rangoon': '+06:30',
  'Asia/Riyadh': '+03:00',
  'Asia/Saigon': '+07:00',
  'Asia/Sakhalin': '+11:00',
  'Asia/Samarkand': '+05:00',
  'Asia/Seoul': '+09:00',
  'Asia/Shanghai': '+08:00',
  'Asia/Singapore': '+08:00',
  'Asia/Srednekolymsk': '+11:00',
  'Asia/Taipei': '+08:00',
  'Asia/Tashkent': '+05:00',
  'Asia/Tbilisi': '+04:00',
  'Asia/Tehran': '+03:30',
  'Asia/Tel_Aviv': '+02:00',
  'Asia/Thimbu': '+06:00',
  'Asia/Thimphu': '+06:00',
  'Asia/Tokyo': '+09:00',
  'Asia/Tomsk': '+07:00',
  'Asia/Ujung_Pandang': '+08:00',
  'Asia/Ulaanbaatar': '+08:00',
  'Asia/Ulan_Bator': '+08:00',
  'Asia/Urumqi': '+06:00',
  'Asia/Ust-Nera': '+10:00',
  'Asia/Vientiane': '+07:00',
  'Asia/Vladivostok': '+10:00',
  'Asia/Yakutsk': '+09:00',
  'Asia/Yangon': '+06:30',
  'Asia/Yekaterinburg': '+05:00',
  'Asia/Yerevan': '+04:00',
  'Atlantic/Azores': '-01:00',
  'Atlantic/Bermuda': '-04:00',
  'Atlantic/Canary': '+00:00',
  'Atlantic/Cape_Verde': '-01:00',
  'Atlantic/Faeroe': '+00:00',
  'Atlantic/Faroe': '+00:00',
  'Atlantic/Jan_Mayen': '+01:00',
  'Atlantic/Madeira': '+00:00',
  'Atlantic/Reykjavik': '+00:00',
  'Atlantic/South_Georgia': '-02:00',
  'Atlantic/St_Helena': '+00:00',
  'Atlantic/Stanley': '-03:00',
  'Australia/ACT': '+11:00',
  'Australia/Adelaide': '+10:30',
  'Australia/Brisbane': '+10:00',
  'Australia/Broken_Hill': '+10:30',
  'Australia/Canberra': '+11:00',
  'Australia/Currie': '+11:00',
  'Australia/Darwin': '+09:30',
  'Australia/Eucla': '+08:45',
  'Australia/Hobart': '+11:00',
  'Australia/LHI': '+11:00',
  'Australia/Lindeman': '+10:00',
  'Australia/Lord_Howe': '+11:00',
  'Australia/Melbourne': '+11:00',
  'Australia/NSW': '+11:00',
  'Australia/North': '+09:30',
  'Australia/Perth': '+08:00',
  'Australia/Queensland': '+10:00',
  'Australia/South': '+10:30',
  'Australia/Sydney': '+11:00',
  'Australia/Tasmania': '+11:00',
  'Australia/Victoria': '+11:00',
  'Australia/West': '+08:00',
  'Australia/Yancowinna': '+10:30',
  'Brazil/Acre': '-05:00',
  'Brazil/DeNoronha': '-02:00',
  'Brazil/East': '-03:00',
  'Brazil/West': '-04:00',
  'CET': '+01:00',
  'CST6CDT': '-06:00',
  'Canada/Atlantic': '-04:00',
  'Canada/Central': '-06:00',
  'Canada/Eastern': '-05:00',
  'Canada/Mountain': '-07:00',
  'Canada/Newfoundland': '-03:30',
  'Canada/Pacific': '-08:00',
  'Canada/Saskatchewan': '-06:00',
  'Canada/Yukon': '-07:00',
  'Chile/Continental': '-03:00',
  'Chile/EasterIsland': '-05:00',
  'Cuba': '-05:00',
  'EET': '+02:00',
  'EST': '-05:00',
  'EST5EDT': '-05:00',
  'Egypt': '+02:00',
  'Eire': '+00:00',
  'Etc/GMT': '+00:00',
  'Etc/GMT+0': '+00:00',
  'Etc/GMT+1': '-01:00',
  'Etc/GMT+10': '-10:00',
  'Etc/GMT+11': '-11:00',
  'Etc/GMT+12': '-12:00',
  'Etc/GMT+2': '-02:00',
  'Etc/GMT+3': '-03:00',
  'Etc/GMT+4': '-04:00',
  'Etc/GMT+5': '-05:00',
  'Etc/GMT+6': '-06:00',
  'Etc/GMT+7': '-07:00',
  'Etc/GMT+8': '-08:00',
  'Etc/GMT+9': '-09:00',
  'Etc/GMT-0': '+00:00',
  'Etc/GMT-1': '+01:00',
  'Etc/GMT-10': '+10:00',
  'Etc/GMT-11': '+11:00',
  'Etc/GMT-12': '+12:00',
  'Etc/GMT-13': '+13:00',
  'Etc/GMT-14': '+14:00',
  'Etc/GMT-2': '+02:00',
  'Etc/GMT-3': '+03:00',
  'Etc/GMT-4': '+04:00',
  'Etc/GMT-5': '+05:00',
  'Etc/GMT-6': '+06:00',
  'Etc/GMT-7': '+07:00',
  'Etc/GMT-8': '+08:00',
  'Etc/GMT-9': '+09:00',
  'Etc/GMT0': '+00:00',
  'Etc/Greenwich': '+00:00',
  'Etc/UCT': '+00:00',
  'Etc/UTC': '+00:00',
  'Etc/Universal': '+00:00',
  'Etc/Zulu': '+00:00',
  'Europe/Amsterdam': '+01:00',
  'Europe/Andorra': '+01:00',
  'Europe/Astrakhan': '+04:00',
  'Europe/Athens': '+02:00',
  'Europe/Belfast': '+00:00',
  'Europe/Belgrade': '+01:00',
  'Europe/Berlin': '+01:00',
  'Europe/Bratislava': '+01:00',
  'Europe/Brussels': '+01:00',
  'Europe/Bucharest': '+02:00',
  'Europe/Budapest': '+01:00',
  'Europe/Busingen': '+01:00',
  'Europe/Chisinau': '+02:00',
  'Europe/Copenhagen': '+01:00',
  'Europe/Dublin': '+00:00',
  'Europe/Gibraltar': '+01:00',
  'Europe/Guernsey': '+00:00',
  'Europe/Helsinki': '+02:00',
  'Europe/Isle_of_Man': '+00:00',
  'Europe/Istanbul': '+03:00',
  'Europe/Jersey': '+00:00',
  'Europe/Kaliningrad': '+02:00',
  'Europe/Kiev': '+02:00',
  'Europe/Kirov': '+03:00',
  'Europe/Kyiv': '+02:00',
  'Europe/Lisbon': '+00:00',
  'Europe/Ljubljana': '+01:00',
  'Europe/London': '+00:00',
  'Europe/Luxembourg': '+01:00',
  'Europe/Madrid': '+01:00',
  'Europe/Malta': '+01:00',
  'Europe/Mariehamn': '+02:00',
  'Europe/Minsk': '+03:00',
  'Europe/Monaco': '+01:00',
  'Europe/Moscow': '+03:00',
  'Europe/Nicosia': '+02:00',
  'Europe/Oslo': '+01:00',
  'Europe/Paris': '+01:00',
  'Europe/Podgorica': '+01:00',
  'Europe/Prague': '+01:00',
  'Europe/Riga': '+02:00',
  'Europe/Rome': '+01:00',
  'Europe/Samara': '+04:00',
  'Europe/San_Marino': '+01:00',
  'Europe/Sarajevo': '+01:00',
  'Europe/Saratov': '+04:00',
  'Europe/Simferopol': '+03:00',
  'Europe/Skopje': '+01:00',
  'Europe/Sofia': '+02:00',
  'Europe/Stockholm': '+01:00',
  'Europe/Tallinn': '+02:00',
  'Europe/Tirane': '+01:00',
  'Europe/Tiraspol': '+02:00',
  'Europe/Ulyanovsk': '+04:00',
  'Europe/Uzhgorod': '+02:00',
  'Europe/Vaduz': '+01:00',
  'Europe/Vatican': '+01:00',
  'Europe/Vienna': '+01:00',
  'Europe/Vilnius': '+02:00',
  'Europe/Volgograd': '+03:00',
  'Europe/Warsaw': '+01:00',
  'Europe/Zagreb': '+01:00',
  'Europe/Zaporozhye': '+02:00',
  'Europe/Zurich': '+01:00',
  'GB': '+00:00',
  'GB-Eire': '+00:00',
  'GMT': '+00:00',
  'GMT+0': '+00:00',
  'GMT-0': '+00:00',
  'GMT0': '+00:00',
  'Greenwich': '+00:00',
  'HST': '-10:00',
  'Hongkong': '+08:00',
  'Iceland': '+00:00',
  'Indian/Antananarivo': '+03:00',
  'Indian/Chagos': '+06:00',
  'Indian/Christmas': '+07:00',
  'Indian/Cocos': '+06:30',
  'Indian/Comoro': '+03:00',
  'Indian/Kerguelen': '+05:00',
  'Indian/Mahe': '+04:00',
  'Indian/Maldives': '+05:00',
  'Indian/Mauritius': '+04:00',
  'Indian/Mayotte': '+03:00',
  'Indian/Reunion': '+04:00',
  'Iran': '+03:30',
  'Israel': '+02:00',
  'Jamaica': '-05:00',
  'Japan': '+09:00',
  'Kwajalein': '+12:00',
  'Libya': '+02:00',
  'MET': '+01:00',
  'MST': '-07:00',
  'MST7MDT': '-07:00',
  'Mexico/BajaNorte': '-08:00',
  'Mexico/BajaSur': '-07:00',
  'Mexico/General': '-06:00',
  'NZ': '+13:00',
  'NZ-CHAT': '+13:45',
  'Navajo': '-07:00',
  'PRC': '+08:00',
  'PST8PDT': '-08:00',
  'Pacific/Apia': '+13:00',
  'Pacific/Auckland': '+13:00',
  'Pacific/Bougainville': '+11:00',
  'Pacific/Chatham': '+13:45',
  'Pacific/Chuuk': '+10:00',
  'Pacific/Easter': '-05:00',
  'Pacific/Efate': '+11:00',
  'Pacific/Enderbury': '+13:00',
  'Pacific/Fakaofo': '+13:00',
  'Pacific/Fiji': '+12:00',
  'Pacific/Funafuti': '+12:00',
  'Pacific/Galapagos': '-06:00',
  'Pacific/Gambier': '-09:00',
  'Pacific/Guadalcanal': '+11:00',
  'Pacific/Guam': '+10:00',
  'Pacific/Honolulu': '-10:00',
  'Pacific/Johnston': '-10:00',
  'Pacific/Kanton': '+13:00',
  'Pacific/Kiritimati': '+14:00',
  'Pacific/Kosrae': '+11:00',
  'Pacific/Kwajalein': '+12:00',
  'Pacific/Majuro': '+12:00',
  'Pacific/Marquesas': '-09:30',
  'Pacific/Midway': '-11:00',
  'Pacific/Nauru': '+12:00',
  'Pacific/Niue': '-11:00',
  'Pacific/Norfolk': '+12:00',
  'Pacific/Noumea': '+11:00',
  'Pacific/Pago_Pago': '-11:00',
  'Pacific/Palau': '+09:00',
  'Pacific/Pitcairn': '-08:00',
  'Pacific/Pohnpei': '+11:00',
  'Pacific/Ponape': '+11:00',
  'Pacific/Port_Moresby': '+10:00',
  'Pacific/Rarotonga': '-10:00',
  'Pacific/Saipan': '+10:00',
  'Pacific/Samoa': '-11:00',
  'Pacific/Tahiti': '-10:00',
  'Pacific/Tarawa': '+12:00',
  'Pacific/Tongatapu': '+13:00',
  'Pacific/Truk': '+10:00',
  'Pacific/Wake': '+12:00',
  'Pacific/Wallis': '+12:00',
  'Pacific/Yap': '+10:00',
  'Poland': '+01:00',
  'Portugal': '+00:00',
  'ROC': '+08:00',
  'ROK': '+09:00',
  'Singapore': '+08:00',
  'Turkey': '+03:00',
  'UCT': '+00:00',
  'US/Alaska': '-09:00',
  'US/Aleutian': '-10:00',
  'US/Arizona': '-07:00',
  'US/Central': '-06:00',
  'US/East-Indiana': '-05:00',
  'US/Eastern': '-05:00',
  'US/Hawaii': '-10:00',
  'US/Indiana-Starke': '-06:00',
  'US/Michigan': '-05:00',
  'US/Mountain': '-07:00',
  'US/Pacific': '-08:00',
  'US/Samoa': '-11:00',
  'UTC': '+00:00',
  'Universal': '+00:00',
  'W-SU': '+03:00',
  'WET': '+00:00',
  'Zulu': '+00:00',
}

export const signupStateStorageKey = 'signup-state'
export const asideStateStorageKey = 'aside-state'
