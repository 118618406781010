import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import { getRequest } from './api'
import {
  removeValue,
  resetAccountChecks,
  updateStatsSeparator,
} from '../components/shared/helpers'
import { resetCache } from '../components/shared/hooks/cache'

const authCookie = 'publishers_session'
const demoCookie = 'publishers_session_demo'
const adblockCookie = 'adb_check'
const currencyCookie = 'currency'
const timezoneCookie = 'tz'
let tokenMemo = ''
let jwtMemo: any = undefined
let userMemo: any = undefined
let accountUuidMemo: string = ''
let currencyMemo: string | undefined = undefined
let timezoneMemo: string | undefined = undefined

export const login = (newToken) => {
  // Logout from demo
  Cookies.remove(demoCookie)
  // Reset datePicker on login
  removeValue('datePickerRangeLast')
  // Reset cached values
  resetCache()
  resetAccountChecks()

  Cookies.set(authCookie, newToken)
  tokenMemo = newToken
  jwtMemo = JSON.parse(window.atob(newToken.split('.')[1]))
  accountUuidMemo = jwtMemo.account.accountUuid
}

export const loginDemo = (newToken) => {
  Cookies.set(demoCookie, newToken)
  tokenMemo = newToken
  jwtMemo = JSON.parse(window.atob(newToken.split('.')[1]))
  accountUuidMemo = jwtMemo.account.accountUuid
  getRequest('/profile/v1/me').then((response) => {
    userMemo = response?.data
  })
}

export const logout = () => {
  Cookies.remove(authCookie)
  Cookies.remove(demoCookie)
  Cookies.remove(adblockCookie)
  Cookies.remove(currencyCookie)
  tokenMemo = ''
}

export const logoutDemo = () => {
  if (!isDemo()) return
  Cookies.remove(demoCookie)
  const token = Cookies.get(authCookie)
  if (token) {
    tokenMemo = token
    jwtMemo = JSON.parse(window.atob(token.split('.')[1]))
    accountUuidMemo = jwtMemo.account.accountUuid
    getRequest('/profile/v1/me').then((response) => {
      userMemo = response?.data
    })
  } else {
    tokenMemo = ''
    jwtMemo = undefined
    userMemo = undefined
    accountUuidMemo = ''
    window.location.href = '/signup/'
  }
}

export const getToken = () => {
  if (tokenMemo) return tokenMemo
  const demoToken = Cookies.get(demoCookie)
  const authToken = Cookies.get(authCookie)
  return (tokenMemo = demoToken || authToken)
}

export const updateToken = (newToken: string) => {
  Cookies.set(authCookie, newToken)
  tokenMemo = newToken
  jwtMemo = JSON.parse(window.atob(newToken.split('.')[1]))
  accountUuidMemo = jwtMemo.account.accountUuid
}

export const isLoggedIn = () => {
  return !!Cookies.get(authCookie)
}

export const isDemo = () => {
  return !!Cookies.get(demoCookie)
}

export const checkToken = async (token: string) => {
  if (token) {
    try {
      const currentUser = await getRequest('/profile/v1/me')
      userMemo = currentUser?.data
      updateStatsSeparator(userMemo?.userCurrencyCode || 'DKK')
      return !!userMemo
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    return false
  }
}

export const getCurrentUser = () => {
  if (jwtMemo && !isEmpty(userMemo)) return { ...jwtMemo, user: userMemo }
  const token = getToken() || ''
  if (token) {
    try {
      jwtMemo = JSON.parse(window.atob(token.split('.')[1]))
      if (isEmpty(userMemo)) checkToken(token).then((correct) => correct)
    } catch {
      // JWT is incorrect
      logout()
      jwtMemo = {}
      userMemo = {}
    }
  } else {
    // Not logged in
    jwtMemo = {}
    userMemo = {}
  }
  return { ...jwtMemo, user: userMemo }
}

export const getCurrentAccountUuid = (): string => {
  if (accountUuidMemo) return accountUuidMemo
  return (accountUuidMemo = jwtMemo?.account ? jwtMemo.account.accountUuid : '')
}

export const setCurrentCurrency = (newCurrencyCode) => {
  Cookies.set(currencyCookie, newCurrencyCode)
  currencyMemo = newCurrencyCode
  updateStatsSeparator(currencyMemo || 'DKK')
}

export const getCurrentCurrency = () => {
  if (currencyMemo) return currencyMemo
  const storedCurrency = Cookies.get(currencyCookie)
  currencyMemo =
    (storedCurrency && storedCurrency !== 'undefined' ? storedCurrency : '') ||
    getCurrentUser().currency?.currencyCode ||
    'DKK'
  updateStatsSeparator(currencyMemo || 'DKK')
  return currencyMemo
}

export const setCurrentTimezone = (newTimezone) => {
  Cookies.set(timezoneCookie, newTimezone)
  timezoneMemo = newTimezone
  updateStatsSeparator(timezoneMemo || 'DKK')
}

export const getCurrentTimezone = () => {
  if (timezoneMemo) return timezoneMemo
  const storedTimezone = Cookies.get(timezoneCookie)
  timezoneMemo =
    (storedTimezone && storedTimezone !== 'undefined' ? storedTimezone : '') ||
    getCurrentUser().time?.timezone ||
    'Europe/Copenhagen'
  updateStatsSeparator(timezoneMemo || 'DKK')
  return timezoneMemo
}
