export const saveValue = (key: string, value: string | number | Date) =>
  localStorage.setItem(key, value.toString())

export const loadValue = (key: string, defaultValue = undefined) =>
  localStorage.getItem(key) || defaultValue

export const loadDateValue = (key: string, defaultValue = undefined) => {
  const value = localStorage.getItem(key)
  if (value) {
    return new Date(value)
  } else {
    return defaultValue
  }
}

export const loadObjectValue = (key: string, defaultValue = {}) =>
  JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue))

export const removeValue = (key: string) => localStorage.removeItem(key)
