import moment from 'moment'
import { shortMonths, timezoneOffsets } from './constants'
import { getCurrentTimezone } from '../../../services/auth'
import { isInvalidDate } from './fns'

export const getCampaignStatus = (campaign): string => {
  if (campaign.campaignIsRejected) return 'rejected'
  if (campaign.campaignIsActive) return 'active'
  if (campaign.campaignIsApplied) return 'applied'
  return 'pending'
}

export const getCampaignTrackStatus = (campaign): string => {
  if (campaign.campaignIsPriority) return 'prioritized'
  if (campaign.campaignIsExcluded) return 'disabled'
  return 'automized'
}

export const formatShortDatetimeStr = (str: string, separator = ''): string => {
  if (isInvalidDate(str)) return ''

  const [date, time] = str.split(' ')
  const [year, month, day] = date.split('-')

  return `${day} ${shortMonths[month]} ${year}${separator} ${time.replace(
    /:\d\d$/,
    ''
  )}`
}

export const formatShortDateStr = (str: string): string => {
  if (isInvalidDate(str)) return ''

  const date = str.includes(' ') ? str.split(' ')[0] : str
  const [year, month, day] = date.split('-')

  return `${day} ${shortMonths[month]} ${year}`
}

export const inTimezone = (
  datetimeStr: string,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  try {
    const tz = getCurrentTimezone()
    return moment
      .utc(datetimeStr)
      .utcOffset(timezoneOffsets[tz || 'Europe/Copenhagen'] || '+01:00')
      .subtract(1, 'hour')
      .format(format)
  } catch (e) {
    console.warn(e)
    return datetimeStr
  }
}

export const dateDiff = (
  dateA: any,
  dateB: any,
  measure: any = 'days'
): number => Math.abs(moment(dateA).diff(moment(dateB), measure))
