export const varClass = (classes: { [key: string]: boolean | any }) => {
  let result = ''
  for (const klass in classes) {
    if (classes[klass]) {
      result += ' ' + klass
    }
  }
  return result.trim()
}

export const srcSet2x = (src: string) => {
  const dotIndex = src.lastIndexOf('.')

  return {
    src,
    srcSet: `${src} 1x, ${src.substring(0, dotIndex)}.2x${src.substring(
      dotIndex
    )} 2x`,
  }
}

export const invokeModal = (id: string) => {
  const element: HTMLElement | null = document.querySelector(`#${id}`)
  element?.click()
}

export const indexOfElement = (el: HTMLElement): number => {
  return Array.from(el.parentElement.children).indexOf(el)
}
