import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

export const Link = (props) => {
  const onClick =
    props.onClick ||
    ((event: React.MouseEvent) => {
      event.preventDefault()
      navigateTo(event, props.href!)
    })

  return <a {...props} onClick={onClick} />
}

export const navigateTo = (
  event: React.MouseEvent | string,
  target?: string
) => {
  if (!target && typeof event === 'string') {
    navigate(event)
  } else if (
    typeof event === 'object' &&
    (event.ctrlKey || event.metaKey || event.button === 2)
  ) {
    window.open(target, '_blank')
  } else if (target) {
    navigate(target)
  }
}

export const navigateReload = () => {
  setTimeout(() => {
    navigate(window.location.pathname, {
      state: { reload: true },
      replace: true,
    })
  }, 0)
}

export const useNavigateReload = (state) => {
  const [render, setRender] = useState(true)

  const reload = state?.reload
  useEffect(() => {
    if (reload) {
      setRender(false)
      setTimeout(() => {
        setRender(true)
        navigate(window.location.pathname, {
          state: { reload: false },
          replace: true,
        })
      }, 300)
    }
  }, [reload])

  return render
}
