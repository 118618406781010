import { getToken, logout, isDemo } from './auth'
import { invokeModal, sleep } from '../components/shared/helpers'

const apiHost = process.env.BACKEND_HOST!.replace(/\/$/, '')
const apiPathPrefix = '/outbound'

// const cachePaths = [
//   '/advs/v1/list',
//   '/networks/v1/list',
//   '/channels/v1/list',
//   '/countries/v1/list',
//   '/currencies/v1/list',
// ]

const requestQueue: { [key: string]: Promise<any> | undefined } = {}

const apiFetch = (path: string, options?: any) => {
  options = options || {}
  options.method = options.method || 'GET'
  options.headers = options.headers || {
    'Content-Type': 'application/json',
  }
  // options.mode = 'no-cors'

  // if (cachePaths.includes(path)) {
  //   options.cache = 'force-cache'
  //   options.headers['Cache-Control'] = 'max-age=14400'
  //   options.headers['Vary'] = 'Authorization'
  // } else {
  //   options.headers['Cache-Control'] = 'no-cache'
  // }

  options.headers['Cache-Control'] = 'no-cache'

  const authToken = getToken()
  if (authToken) {
    options.headers.Authorization = `Bearer ${authToken}`
  }

  return apiFetchWithRetry(`${apiHost}${apiPathPrefix}${path}`, options, 5)
}

const apiFetchWithRetry = (url: string, options: any, retries = 3) => {
  const key = options.method + url + JSON.stringify(options.body)
  if (requestQueue[key]) {
    return requestQueue[key]
  }
  const requestPromise = window
    .fetch(url, options)
    .then(async (raw) => {
      delete requestQueue[key]
      const data = await raw.json()
      if (data.code === 401 && data.message === 'Invalid auth token') {
        logout()
        window.location.reload()
      } else if (
        data.code === 401 &&
        data.message === 'Access denied' &&
        isDemo()
      ) {
        invokeModal('demo-no-access-modal')
      }
      return data
    })
    .catch(async (error) => {
      delete requestQueue[key]
      if (retries > 0) {
        await sleep(1000)
        return apiFetchWithRetry(url, options, retries - 1)
      } else {
        console.error(`${url}:`, error)
        return {}
      }
    })
  requestQueue[key] = requestPromise
  return requestPromise
}

export const getRequest = (path: string, params?: any) => {
  if (params) {
    const divider = path.includes('?') ? '&' : '?'
    const fullPath = `${path}${divider}${new URLSearchParams(
      params
    ).toString()}`
    return apiFetch(fullPath)
  } else {
    return apiFetch(path)
  }
}

export const postRequest = (path: string, body: any) =>
  apiFetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
  })

export const putRequest = (path: string, body: any) =>
  apiFetch(path, {
    method: 'PUT',
    body: JSON.stringify(body),
  })

export const deleteRequest = (path: string, params?: any) => {
  if (params) {
    const divider = path.includes('?') ? '&' : '?'
    const fullPath = `${path}${divider}${new URLSearchParams(
      params
    ).toString()}`
    return apiFetch(fullPath, { method: 'DELETE' })
  } else {
    return apiFetch(path, { method: 'DELETE' })
  }
}

export const uploadFileRequest = (path: string, file: any, params?: any) => {
  const formData = new FormData()
  formData.append('file', file)

  if (params) {
    const divider = path.includes('?') ? '&' : '?'
    path = `${path}${divider}${new URLSearchParams(params).toString()}`
  }

  return apiFetch(path, {
    method: 'POST',
    body: formData,
    headers: {},
  })
}
